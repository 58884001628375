import React from 'react'
import { VscFilePdf, VscFileZip, VscFile, VscDeviceCameraVideo } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children } = props
    const { type, name, size } = file

    const renderThumbnail = () => {
        return file?.type?.split('/')[0] === 'image' ? (
            <img
                className="upload-file-image"
                src={URL.createObjectURL(file)}
                alt={`file preview ${name}`}
            />
        ) : (

            file.contentType === 'video/mp4' ?
                <a href={file?.file} target='_blank'>
                    <FileIcon>
                        <VscDeviceCameraVideo />
                    </FileIcon>
                </a>
            : 
                <a href={file?.file} target='_blank'>
                    <img
                    className="upload-file-image"
                    src={file?.file}
                    alt={file?.fileName}
                    />
                </a>
        );

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <div className="upload-file">
            <div className="flex">
                <div className="upload-file-thumbnail">{renderThumbnail()}</div>
                <div className="upload-file-info">
                    <h6 className="upload-file-name">{name || file?.originalName}</h6>
                    <span className="upload-file-size">{getKB(size)} kb</span>
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem
