import { MuiChipsInput } from 'mui-chips-input'
import React from 'react'

const ChipsInputField = ({ field, form, label, ...props }) => {
    const handleChange = (value) => {
      form.setFieldValue(field.name, value);
    };
  
    return (
      <div>
        <label>{label}</label>
        <MuiChipsInput
          {...field}
          fullWidth
          {...props}
          onChange={handleChange}
          onBlur={() => form.setFieldTouched(field.name, true)}
        />
      </div>
    );
  };
  
  export default ChipsInputField;
  
